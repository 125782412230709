<template>
  <q-header>
    <q-toolbar class="text-white custom-nav" style="width: auto">
      <div class="col-2">
        <a href="profile" class="heading">zerudite</a>
      </div>
      <div class="col-3"></div>
      <div class="col-7">
        <a href="profile" class="nav-urls">Profile</a>
        <a href="requests" class="nav-urls">Request a Session</a>
        <a href="search" class="nav-urls">Tutor Others</a>
        <a href="shop" class="nav-urls">Shop Credits</a>
        <a href="" class="nav-urls" @click="signOut">Log Out</a>
      </div>
      <div class="col-4" style="margin-left: 5vw;">
           </div>

    </q-toolbar>
  </q-header>
  <q-dialog v-model="feedbackDialog" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Feedback Form Pending</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          Congratulations on finishing your learning session with <b style="font-family: Montserrat; font-weight: 700">zerudite</b>! To keep our platform a high quality environment, we ask everyone for feedback regarding each session they participate in. Before you can access the platform, please fill out the feedback form.
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn push label="Give Feedback" color="teal-10" v-close-popup style="font-family: Lato" no-caps @click="showFeedbackForm"/>

      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="badReviewWarning" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Bad Feedback Warning</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          Oh no! Your last session was reported to not have been a very good one! We were told that some issues occurred, so please take this time to reflect on what happened and try to see if you can correct it for next time. Please note that consistent patterns of negative session experiences of others with you may lead to a ban from our platform. This is necessary to keep our platform a high quality learning environment. However, we are confident that you will continue to improve! We all make mistakes!
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn push label="Understood. I will take care next time." color="teal-10" type="submit" style="font-family: Lato" no-caps @click="badReviewFix"/>

      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="missedTutorWarning" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Missed Session Warning</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          Oh no! You missed your last session where you were supposed to be a tutor! It is important for our users to be on time as students are busy people. Please try not to be late to future sessions. As a penalty, 100 credits have been deducted from your account.
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn push label="Understood. I will take care next time." color="teal-10" type="submit" style="font-family: Lato" no-caps @click="missedTutorFix"/>

      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="missedRequestorWarning" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Missed Session Warning</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          Oh no! You missed your last session where you were supposed to be a student! It is important for our users to be on time as students are busy people. Please try not to be late to future sessions. As a penalty, 100 credits have been deducted from your account. Furthermore, the bounty you paid for the session will not be refunded.
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn push label="Understood. I will take care next time." color="teal-10" type="submit" style="font-family: Lato" no-caps @click="missedRequestorFix"/>

      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="badReviewWarning" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Bad Feedback Warning</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          Oh no! Your last session was reported to not have been a very good one! We were told that some issues occurred, so please take this time to reflect on what happened and try to see if you can correct it for next time. Please note that consistent patterns of negative session experiences of others with you may lead to a ban from our platform. This is necessary to keep our platform a high quality learning environment. However, we are confident that you will continue to improve! We all make mistakes!
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn push label="Understood. I will take care next time." color="teal-10" type="submit" style="font-family: Lato" no-caps @click="badReviewFix"/>

      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="banned" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Banned</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          You have been banned from <b style="font-family: Montserrat; font-weight: 700">zerudite</b>. You will now be redirected to the home page.
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn push label="OK" color="teal-10" type="submit" style="font-family: Lato" no-caps @click="banRedirect"/>

      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="sessionWarning" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Upcoming Session</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          You have a session at {{sessionTime}}! Get there before its too late! Remember, being later than 5 minutes to a session will result in a penalty! You can join it from the sessions page as well.
        </p>

      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn flat label="Got the info, but don't redirect me!" color="red-10" v-close-popup style="font-family: Lato" no-caps/>
        <q-btn push label="Go to the session!" color="teal-10" type="submit" style="font-family: Lato" no-caps @click="$router.replace('/session/' + sessionURL)"/>

      </q-card-actions>
    </q-card>
  </q-dialog>


  <q-dialog v-model="feedbackForm" no-esc-dismiss no-backdrop-dismiss persistent>
    <q-card flat bordered style="width: 40vw">
      <q-card-section>
        <div class="text-h5 q-mb-xs-xs" style="font-family: Lato">Feedback Form Pending</div>
      </q-card-section>
      <q-separator />
      <q-card-section style="padding-top: 0.5vh">
        <p class="text-h7" style="font-family: Lato; text-align: left; margin-bottom: 0">
          Please give us <b>honest feedback</b> so that we can improve our platform! Be honest - if the session went well, please tell us that! If you had issues, please describe them in detail. Please note that if you indicate that there were issues, the other party will <b>receive a strike</b> (consistent strikes lead to bans).
        </p>
        <p v-if="feedbackFormFor === 'requestor'" class="text-h7 text-bold" style="font-family: Lato; text-align: left; margin-bottom: 3vh">
          How helpful was the student in explaining their question?
        </p>
        <p v-else class="text-h7 text-bold" style="font-family: Lato; text-align: left; margin-bottom: 3vh">
          How helpful was the tutor?
        </p>
        <q-slider
            v-model="feedbackFormResult.overallRating"
            :min="1"
            :max="5"
            :step="1"
            snap
            label
            label-always
            :label-value="overallLabels[feedbackFormResult.overallRating - 1]"
            color="teal-10"
        />

        <p v-if="feedbackFormFor === 'requestor'" class="text-h7 text-bold" style="font-family: Lato; text-align: left; margin-bottom: 3vh">
          How well did the student interact with you?
        </p>
        <p v-else class="text-h7 text-bold" style="font-family: Lato; text-align: left; margin-bottom: 3vh">
          How well did the tutor interact with you?
        </p>
        <q-slider
            v-model="feedbackFormResult.interactionRating"
            :min="1"
            :max="5"
            snap
            label
            label-always
            :label-value="interactionLabels[feedbackFormResult.interactionRating - 1]"
            color="teal-10"
        />

        <p v-if="feedbackFormFor === 'requestor'" class="text-h7 text-bold" style="font-family: Lato; text-align: left; margin-bottom: 3vh">
          How was the video and audio quality of the student?
        </p>
        <p v-else class="text-h7 text-bold" style="font-family: Lato; text-align: left; margin-bottom: 3vh">
          How was the video and audio quality of the tutor?
        </p>
        <q-slider
            v-model="feedbackFormResult.callRating"
            :min="1"
            :max="5"
            snap
            label
            label-always
            :label-value="callLabels[feedbackFormResult.callRating - 1]"
            color="teal-10"
        />


      </q-card-section>

      <q-separator />

      <q-card-actions align="right">

        <q-btn push label="Submit Feedback" color="teal-10" type="submit" style="font-family: Lato" no-caps @click="submitFeedback"/>

      </q-card-actions>
    </q-card>
  </q-dialog>



</template>

<script>
import {Dialog, QToolbar} from 'quasar'
import firebase from "firebase";
import {getCurrentInstance, ref} from "vue";


export default {
  name: "InternalNavBar",
  components: {
    QToolbar
  },
  setup () {
    const internalInstance = getCurrentInstance()

    const currentURL = window.location.pathname

    var feedbackDialog = ref(false)
    var feedbackForm = ref(false)
    var feedbackFormFor = ref("")
    var feedbackSessionID = ref(null)

    var sessionWarning = ref(false)
    var sessionTime = ref(null)
    var sessionURL = ref("")

    var banned = ref(false)

    var badReviewWarning = ref(false)
    var missedTutorWarning = ref(false)
    var missedRequestorWarning = ref(false)

    var feedbackFormResult = ref({
      overallRating: 5,
      interactionRating: 5,
      callRating: 5
    })

    const overallLabels = ["Not helpful", "Somewhat Helpful", "Helpful", "Rather Helpful", "Very Helpful"]
    const interactionLabels = ["Did not interact", "Subpar interaction", "Interacted somewhat", "Interacted quite a bit", "Interacted very well"]
    const callLabels = ["Not good (lots of lag, choppy video, etc.)", "Subpar quality", "Average quality", "Good quality", "Amazing quality"]

    return {
      internalInstance,
      feedbackDialog,
      feedbackForm,
      sessionWarning,
      feedbackFormFor,
      feedbackFormResult,
      overallLabels,
      interactionLabels,
      callLabels,
      feedbackSessionID,
      sessionTime,
      banned,
      badReviewWarning,
      sessionURL,
      currentURL,
      missedRequestorWarning,
      missedTutorWarning
    }
  },
  methods: {
    async signOut() {
      await firebase.auth().onAuthStateChanged(() => {
        firebase.auth().signOut()
      })
    },
    showFeedbackForm() {
      this.feedbackDialog = false
      this.feedbackForm = true
    },
    async submitFeedback() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        let url = this.internalInstance.appContext.config.globalProperties.$backendURL
        let IDToken = await user.getIdToken(true)

        this.feedbackFormResult.feedbackFor = this.feedbackFormFor

        let response = await fetch(url + '/submitFeedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: IDToken,
            sessionID: this.feedbackSessionID,
            feedbackForm: this.feedbackFormResult
          })
        })

        let result = await response.json()
        if (result.code !== "SUCCESSFUL") {
          Dialog.create({
            title: 'Feedback Not Submitted',
            message: 'Your feedback was not submitted successfully. Please try again. Error: ' + result.code,
            persistent: true
          }).onOk(async () => {
            this.feedbackForm = false
            await window.location.reload(false)
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        } else {
          Dialog.create({
            title: 'Feedback Submitted',
            message: 'Your feedback was submitted successfully. Thank you for helping improve our platform!',
            persistent: true
          }).onOk(async () => {
            await window.location.reload(false)
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        }
      })
    },
    async badReviewFix() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        let url = this.internalInstance.appContext.config.globalProperties.$backendURL
        let IDToken = await user.getIdToken(true)

        this.feedbackFormResult.feedbackFor = this.feedbackFormFor

        let response = await fetch(url + '/changeBadReview', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: IDToken
          })
        })

        let result = await response.json()

        if (result.code === "SUCCESSFUL") {
          Dialog.create({
            title: 'Thank you!',
            message: 'Thank you for taking a moment to address the issues posed. We hope that you will continue to improve and keep our platform a high quality learning environment for everyone!',
            persistent: true
          }).onOk(async () => {
            await window.location.reload(false)
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        }
      })
    },
    async missedTutorFix() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        let url = this.internalInstance.appContext.config.globalProperties.$backendURL
        let IDToken = await user.getIdToken(true)

        this.feedbackFormResult.feedbackFor = this.feedbackFormFor

        let response = await fetch(url + '/changeMissedTutor', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: IDToken,
          })
        })

        let result = await response.json()

        if (result.code === "SUCCESSFUL") {
          Dialog.create({
            title: 'Thank you!',
            message: 'Thank you for taking a moment to address the issues posed. We hope that you will continue to improve and keep our platform a high quality learning environment for everyone!',
            persistent: true
          }).onOk(async () => {
            await window.location.reload(false)
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        }
      })
    },
    async missedRequestorFix() {
      await firebase.auth().onAuthStateChanged(async (user) => {
        let url = this.internalInstance.appContext.config.globalProperties.$backendURL
        let IDToken = await user.getIdToken(true)

        this.feedbackFormResult.feedbackFor = this.feedbackFormFor

        let response = await fetch(url + '/changeMissedRequestor', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: IDToken,
          })
        })

        let result = await response.json()

        if (result.code === "SUCCESSFUL") {
          Dialog.create({
            title: 'Thank you!',
            message: 'Thank you for taking a moment to address the issues posed. We hope that you will continue to improve and keep our platform a high quality learning environment for everyone!',
            persistent: true
          }).onOk(async () => {
            await window.location.reload(false)
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        }
      })
    },
    async banRedirect() {
      await firebase.auth().onAuthStateChanged(async () => {
        await firebase.auth().signOut()
        await this.$router.replace('/')
      })
    }
  },
  async mounted() {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        await this.$router.push('/403')
        return
      }

      let url = this.internalInstance.appContext.config.globalProperties.$backendURL
      let IDToken = await user.getIdToken(true)

      let response = await fetch(url + '/isUserBanned', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
        })
      })
      let result = await response.json()

      if (result.isUserBanned) {
        this.banned = true
        return
      }

      response = await fetch(url + '/isFeedbackPending', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
        })
      })
      result = await response.json()

      if (result.requestorFeedbackPending) {
        this.feedbackDialog = true
        this.feedbackFormFor = "requestor"
        this.feedbackSessionID = result.sessionID
        return
      }
      if (result.tutorFeedbackPending) {
        this.feedbackDialog = true
        this.feedbackFormFor = "tutor"
        this.feedbackSessionID = result.sessionID
        return
      }

      response = await fetch(url + '/missedSessionAsTutor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
        })
      })
      result = await response.json()

      if (result.missed) {
        this.missedTutorWarning = true
        return
      }

      response = await fetch(url + '/missedSessionAsRequestor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
        })
      })
      result = await response.json()

      if (result.missed) {
        this.missedRequestorWarning = true
        return
      }

      response = await fetch(url + '/hadBadReview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
        })
      })
      result = await response.json()

      if (result.badReview) {
        this.badReviewWarning = true
        return
      }

      response = await fetch(url + '/isSessionSoon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: IDToken,
        })
      })
      result = await response.json()

      if (result.sessionSoon) {
        this.sessionURL = result.sessionURL
        const constdate = new Date()
        let time = new Date(result.sessionTime).getTime()
        let offset = constdate.getTimezoneOffset() * 60000
        let newDate = new Date(time - offset)
        this.sessionTime = Date.parse(newDate).toString("MMM dd, yyyy HH:mm")
        if (newDate.getTime() - constdate.getTime() <= 0) {
          Dialog.create({
            title: 'Your session is in progress!',
            message: 'Please join the session! Clicking OK will take you there.',
            persistent: true
          }).onOk(async () => {
            await this.$router.replace('/session/' + this.sessionURL)
          }).onCancel(() => {
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
        } else {
          this.sessionWarning = true
        }
      }
    })
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

.heading {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 30px;
  color: black;
  text-decoration: none;
  margin-left: 2vw;
}

.custom-nav {
  background-image: url("../assets/thin-internalnavbar (1).png");
  background-size: cover;
  background-position: top left;
  height: 8vh;
}

.nav-urls {
  font-family: Montserrat;
  font-weight: 400;
  color: black;
  font-size: 24px;
  text-decoration: none;
  margin-left: 1vw;
  margin-right: 1vw;
  display: inline-block;
}

.nav-urls:hover {
  color: #004d40;
  -webkit-animation: pulse infinite 1s;
  animation: pulse infinite 1s;
}



</style>